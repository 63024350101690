const Productinfo= [
    {
        id: "1",
        title:"Front Office Management",
        content: "This software optimizes hotel reception operations by streamlining guest check-ins, reservations, and overall desk efficiency. It facilitates smooth guest experiences through efficient check-in processes, seamless reservation management, and enhanced desk operations. By automating tasks and centralizing guest information, it improves operational efficiency, ensuring quick and accurate service delivery. This solution empowers hotel staff to focus more on guest satisfaction, offering a seamless and personalized experience from arrival to departure. It integrates robust features for managing bookings, room allocations, and guest preferences, contributing to enhanced guest loyalty and operational excellence across hospitality environments.",
        img1:"/Images/front-office-1.png",
        img2:"/Images/front-office-2.png",
        services: "At Vang Technologies, we offer a comprehensive suite of front office services designed to enhance security, optimize energy usage, and streamline operations through seamless integration with external platforms. Our services include:",
        service1title: "Door Lock Integration",
        service1subtitle: "Our Door Lock Integration service provides advanced security solutions for your front office. We integrate state-of-the-art locking systems, allowing you to efficiently control and monitor access to your premises. Enhance your security infrastructure with our cutting-edge technology for greater convenience and safety.",
        service2title:"Power Automation Integration",
        service2subtitle: "Our Power Automation Integration service focuses on optimizing energy usage within your front office. By automating power controls and monitoring systems, we help reduce energy consumption and operational costs. Benefit from a more efficient and sustainable power management solution through our innovative automation technologies.",
        service3title: "Third-Party Integration",
        service3subtitle: "Our Third-Party Integration service connects your front office systems with external platforms and services. We ensure seamless data exchange and interoperability, enhancing the functionality and efficiency of your operations. Our third-party facilities include ID card scanning, image capturing, thumb capturing, SMS, WhatsApp, and PhonePe, allowing for secure and efficient identity verification, communication, and payment processing.",
    },
    {
        id: "2",
        title:"Point of Sale",
        content: "This software efficiently manages restaurant operations by tracking orders and displaying comprehensive sales data, ensuring streamlined customer transactions. It enhances operational efficiency by automating order processes, thereby reducing errors and improving accuracy. The software integrates seamlessly with existing restaurant systems, providing real-time insights into sales performance and inventory management. By facilitating quicker order processing and precise sales reporting, it optimizes staff productivity and customer satisfaction. Overall, the software transforms restaurant management by centralizing order tracking and sales data, enabling restaurants to deliver exceptional service and maximize profitability through informed decision-making and operational excellence.",
        img1:"/Images/pos-1.png",
        img2:"/Images/pos-2.png",
        services:"At Vang Technologies, we offer a range of advanced Point of Sale (POS) services designed to enhance the efficiency and effectiveness of your restaurant or retail operations. Our services include:",
        service1title: "Captain App for Order Taking in Restaurants",
        service1subtitle: "Our Captain App is specifically designed to streamline the order-taking process in restaurants. This app allows waitstaff to take orders directly from customers and send them instantly to the kitchen. It reduces errors, speeds up service, and enhances customer satisfaction by ensuring orders are accurately and promptly processed.",
        service2title: "Display System for Kitchen",
        service2subtitle: "Our Kitchen Display System (KDS) improves communication between the front and back of house. Orders taken through the Captain App or POS system are displayed in real-time on kitchen screens, helping kitchen staff manage and prioritize orders efficiently. This system reduces wait times and ensures accurate preparation of dishes.",
        service3title: "Network KOT's Printing:",
        service3subtitle: "Our Network Kitchen Order Tickets (KOT) Printing solution ensures that order tickets are printed across multiple kitchen printers simultaneously. This networked approach streamlines order management, reduces delays, and enhances coordination in busy kitchen environments.",
        service4title: "UPI Payment Integration",
        service4subtitle: "Our UPI Payment Integration service enhances your POS system by enabling direct UPI payments. This service ensures secure transactions, reduces payment processing times, and provides customers with a familiar and convenient payment method."
    },
    {
        id: "3",
        title:"Inventory Management",
        content: "This warehouse inventory management software oversees the flow of goods in and out of warehouses, maintaining precise control over stock levels. It employs robust tracking mechanisms to monitor the movement of items throughout the supply chain, from receipt to distribution. By automating inventory management processes, it minimizes errors and discrepancies, ensuring that stock levels remain accurate and up-to-date at all times. The software provides detailed insights into inventory status, enabling timely replenishment and efficient allocation of resources. Ultimately, it enhances operational efficiency by optimizing inventory control, reducing storage costs, and improving overall supply chain management for enhanced business productivity.",
        img1:"/Images/inventory-1.png",
        img2:"/Images/inventory-2.png"
    },
    {
        id: "4",
        title:"MRP Management software",
        content: "This retail pricing optimization software ensures uniform and precise pricing strategies across multiple stores. By analyzing market trends, competitor pricing, and internal metrics, it adjusts prices dynamically to maximize profitability while maintaining competitiveness. The software employs advanced algorithms to recommend optimal pricing for products, considering factors like demand elasticity and seasonal variations. It streamlines pricing updates across all locations, ensuring consistency and accuracy in product pricing. This capability not only improves profit margins but also enhances customer satisfaction by offering competitive prices that reflect market conditions effectively. Overall, the software empowers retailers to make data-driven pricing decisions that drive sales and profitability.",
        img1:"/Images/mrp-img-1.png",
        img2:"/Images/mrp-img-2.png"
    },

];
export default Productinfo;