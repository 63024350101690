const fact= [
  {
    id:"active_people", 
    title: "500+",
    subtitle: "Active costumers",
    smalltitle: "as of 31 December 2023"
  },
  {
    id:"active_cities", 
    title: "3",
    subtitle: "Cities with active Vang Listing",
    smalltitle: "as of 31 December 2023"
  },
  {
    id:"active_checkins", 
    title: "25K+",
    subtitle: "Check-In Everyday",
    smalltitle: "as of 31 December 2023"
  },
  {
    id:"active_checkins", 
    title: "50K+",
    subtitle: "Check-Outs Everyday",
    smalltitle: "as of 31 December 2023"
  },
  {
    id:"active_liqour_brands", 
    title: "3000+",
    subtitle: "Liqour Brands in Listing",
    smalltitle: "as of 31 December 2023"
  },
  {
    id:"active_liqour_store", 
    title: "500+",
    subtitle: "Liqour Store across Karnataka",
    smalltitle: "as of 31 December 2023"
  },
  {
    id:"active_hotes", 
    title: "200+",
    subtitle: "Hotels pan India",
    smalltitle: "as of 31 December 2023"
  },
  {
    id:"new_costumers", 
    title: "300+",
    subtitle: "Restaurants pan India",
    smalltitle: "as of 31 December 2023"
  }
 ]


export default fact