import React from 'react'
function Statistics() {
  return (
    <div>
        <section class="text-gray-600 body-font">
  <div class="container px-5 pb-24 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
      <div class="p-4 sm:w-1/4 w-1/2">
        <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-900">2016</h2>
        <p class="leading-relaxed">In Buisness Since</p>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
        <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-900">100%</h2>
        <p class="leading-relaxed">Client Satisfaction</p>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
        <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-900">500+</h2>
        <p class="leading-relaxed">Costumers</p>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
        <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-900">4</h2>
        <p class="leading-relaxed">Products</p>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}

export default Statistics