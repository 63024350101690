const costumers = [
    {
        id:"1",
        img:"/Images/atg-hotel.svg",
        name:"Hotel ATG Royal Inn"
    },
    {
        id:"2",
        img:"/Images/ut-elegence.svg",
        name:"UT Elegance"
    },
    {
        id:"3",
        img:"/Images/mallagihotel.svg",
        name:"Hotel Malligi Hospet"
    },
    {
        id:"4",
        img:"/Images/hlv.svg",
        name:"HLV Grand Inn"
    },
    {
        id:"5",
        img:"/Images/shivani.svg",
        name:"Shivani Inn"
    },
    {
        id:"6",
        img:"/Images/shobha.svg",
        name:"Shobha Deluxe Lodge"
    },
    {
        id:"7",
        img:"/Images/renuka.svg",
        name:"Hotel Renuka, Bnagalore"
    },
    {
        id:"8",
        img:"/Images/samara.svg",
        name:"Samara's Veg"
    },
    {
        id:"9",
        img:"/Images/scothyard.svg",
        name:"Scotch Yard Liqour Boutique"
    },
    {
        id:"10",
        img:"/Images/shri-ganesh.svg",
        name:"Hotel Shree Ganesh, Bangalore"
    },
    {
        id:"11",
        img:"/Images/slv.svg",
        name:"SLV Royal Inn, Mysore"
    },
    {
        id:"12",
        img:"/Images/ramya.svg",
        name:"Ramya Residency"
    },
];
export default costumers;