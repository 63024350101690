const Productarray = [
    {
        id: "1",
        img:"/Images/frnt-ofc.svg",
        name: "Front Office Management",
        content: "This software streamlines hotel reception tasks, improving guest check-ins, reservations, and overall desk efficiency.",
        link:  "/products-array/1"        
    },
    {
        id: "2",
        img:"/Images/ptofsale.svg",
        name: "Point of Sale",
        content: "This software tracks restaurant orders, enhancing efficiency and accuracy for customer transactions.",
        link: "/products-array/2",      
    },
    {
        id: "3",
        img:"/Images/invtry.svg",
        name: "Inventory Management", 
        content: "The software manages warehouse inventory, tracking items entering and exiting to ensure accurate stock levels.",
        link: "/products-array/3"           
    },
    {
        id: "4",
        img:"/Images/mrp.svg",
        name: "MRP Management software",
        content: "This software optimizes retail pricing, managing for products to ensure consistent and accurate pricing across stores.",
        link: "/products-array/4"   
    }
]

export default Productarray