

const Testimonialarray = [
    {
        id:'1',
        name: "Kiran Gowda",
        comment: "VANG Technologies' dedication to the hospitality industry is evident through their specialized software solutions. From Front Office Management to MRP Management, their products streamline operations, boosting efficiency and fostering growth. Their commitment to understanding customer needs sets them apart as a trusted partner.",
        post: "Owner of Hotel ATG Royal Inn",
        places:"Hotel ATG Royal Inn"
    },
    {
        id:'2',
        name: "Tejas Gowda",
        comment: "The comprehensive range of services offered by VANG Technologies, including Door Lock Integration and Power Automation Integration, complements their tailored software solutions perfectly. Their customer-centric approach ensures that each solution is designed to meet the unique needs of the hospitality sector, driving business growth and efficiency.",
        post: "Owner of UT Group",
        places:"UT Group"
    },
    {
        id:'3',
        name: "Bharat Gowda",
        comment: "VANG Technologies stands out with their innovative and quality-driven solutions for the hospitality industry. Their deep industry understanding and focus on customer satisfaction have positioned them as a leader in the field, optimizing operations and empowering businesses to reach new heights.",
        post: "Owner of Tirupathi Group",
        places:"Tirupathi Group"
    },
]

export default Testimonialarray